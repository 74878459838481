<template>
  <mynav navIndex="3"></mynav>
  <div class="main">
    <h3><strong>美国非农历史数据(万人)</strong></h3>
    <div class="lineChart-container">
      <canvas id="myChart" class="lineChart"></canvas>
    </div>
    <table class="table table-striped">
    <thead>
    <tr>
    <th class='table-head'>日期</th>
    <th class='table-head'>前值</th>
    <th class='table-head'>预测值</th>
    <th class='table-head'>公布值</th>
    <th class='table-head'>影响</th>
    </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in datas" v-bind:key="index">
      <td>{{data.publish_time}}</td>
      <td>{{data.previous}}</td>
      <td>{{data.forecast}}</td>
      <td>{{data.publish}}</td>
      <td v-html="data.effect"></td>
      </tr>
    </tbody>
    </table>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import axios from "axios";
import datos from "../apps.js"
export default {
  name: 'Feinong',
  data() {
    return {
      configChart: {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          interaction: {
            intersect: false,
            mode: 'index'
          },
          elements:{
            point:{
              radius: 2,
            }
          }
        }
      },
      datas:[],
      num: 0
    }
  },
  mounted() {
    this.num = Math.floor(Math.random()*40)
    this.getData(false)
  },
  unmounted() {
    this.datas = []
  },
  methods: {
    getData(){
      axios
      .get("/" + datos()[this.num].desc + "/v1/feinong/data/list", {params: {
          dtype: this.dtype, page: this.page}})
      .then((data) => {
        if (data.data.code == 200) {
          let lineLables = []
          let lineDataPush = {
            label: '公布值',
            backgroundColor: 'rgb(111, 190, 233)',
            borderColor: 'rgb(111, 190, 233)',
            data: [],
          }
          let lineDataforecast = {
            label: '预测值',
            backgroundColor: 'rgb(250, 188, 146)',
            borderColor: 'rgb(250, 188, 146)',
            data: [],
          }
          for (let index = 0; index < data.data.data.length; index++) {
            var elem = data.data.data[index]
            var effect = ""
            if(elem.effect == 1){
              effect="<strong style='color:#EF5350'>利多</strong>"
            }else if(elem.effect == 2){
              effect="<strong style='color:#26A69A'>利空</strong>"
            }else{
              effect="<strong style='color:#888888'>较弱</strong>"
            }
            
            this.datas.push({
              publish: elem.publish, forecast: elem.forecast,
              effect: effect, previous: elem.previous,
              publish_time: elem.publish_time})

            lineLables.push(elem.publish_time)
            lineDataPush.data.push(elem.publish)
            lineDataforecast.data.push(elem.forecast)
          }
          this.configChart.data.labels=lineLables.reverse()
          lineDataPush.data = lineDataPush.data.reverse()
          lineDataforecast.data = lineDataforecast.data.reverse()
          this.configChart.data.datasets = [lineDataPush, lineDataforecast]
          new Chart(
          document.getElementById('myChart'),
          this.configChart)
        }
      })
      .catch((err) => {
        console.log(err);
      })
    },
  }
}
</script>
<style>
  @import '../css/main.css';
  .lineChart-container{

  }
  .lineChart{
    max-height: 400px;
  }

</style>